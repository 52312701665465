import React, {useState} from "react";
import {Slider} from "./Slider";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export function PizzaCalculator() {
  const [ingridientCalculatorDS, setIngridientCalculatorDS] = useState([]);

  return (
    <>
      <div>
        <h3>Alapanyag kalkulátor</h3>
        {/*<Slider min={0} max={160} value={80} onValueChange={(chValue) => onChMaxChange(chValue)} />*/}

        {/*<div className="action-header margin-top-small">*/}
        {/*  <h3 className="action-header__title">Hozzávalók</h3>*/}
        {/*  <Button onClick={addIngridient}>Új hozzávaló</Button>*/}
        {/*</div>*/}

        {/*<div>*/}
        {/*  {ingridientCalculatorDS.map((ingridient, index) =>*/}
        {/*    <>*/}
        {/*      <div key={index}>*/}
        {/*        <Form.Group>*/}
        {/*          <Form.Label>Alapanyag</Form.Label>*/}
        {/*          <Form.Control as="select"*/}
        {/*                        defaultValue={ingridient.id}*/}
        {/*                        onChange={(event) => setIngridientForCalculator(ingridient, event.target.value)}>*/}
        {/*            {ingridientList.map(ingridientItem => <option value={ingridientItem.id}>{ingridientItem.label}</option>)}*/}
        {/*          </Form.Control>*/}
        {/*        </Form.Group>*/}
        {/*      </div>*/}

        {/*    </>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </>
  );
}
