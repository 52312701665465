import React, {useEffect, useState} from "react";
import {Slider} from "./Slider";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {ingridientList} from "./ingridientList";
import {IngridientSelector} from "./IngridientSelector";

export function PieCalculator() {
  const [pieCalculatorDS, setPieCalculatorDS] = useState([]);
  const [maxCh, setMaxCh] = useState(80);
  const [summary, setSummary] = useState('');

  const addIngridient = () => {
    setPieCalculatorDS(pieCalculatorDS.concat([Object.assign({weight: 1}, ingridientList[0])]));
  };

  const setIngridientForCalculator = (activeIngridient, selectedIngridient) => {
    const previousWeight = activeIngridient.weight;
    const index = pieCalculatorDS.findIndex(pieItem => pieItem.id === activeIngridient.id);
    if (index > -1) {
      pieCalculatorDS[index] = selectedIngridient;
      pieCalculatorDS[index].weight = previousWeight;
      updateSummary();
    }
  };

  const onWeightUpdated = (ingridient, chValue) => {
    ingridient.weight = parseInt(chValue);
    updateSummary();
  };

  const onMaxChChange = (value) => {
    setMaxCh(value);
  };

  const updateSummary = () => {
    let sumWeight = 0;
    let sumCh = 0;
    pieCalculatorDS.forEach(item => {
      if (item.ch && item.weight) {
        sumWeight += item.weight;
        sumCh += Math.floor((item.weight * 100) / item.ch);
      }
    });

    if (sumCh > 0) {
      const allCh = Math.floor((sumWeight * maxCh) / sumCh);
      setSummary(`Összesen ${sumWeight}g, ebből ${sumCh}g szénhidrát.<br>A teljes adagból <strong>${allCh}g</strong> tartalmaz ${maxCh}g szénhidrátot.`);
    } else {
      setSummary('');
    }
  };

  const removeIngridient = (ingridient) => {
    const index = pieCalculatorDS.findIndex(pieItem => pieItem.id === ingridient.id);
    if (index > -1) {
      pieCalculatorDS.splice(index, 1);
      setPieCalculatorDS(JSON.parse(JSON.stringify(pieCalculatorDS)));
    }
  };

  useEffect(() => {
    updateSummary();
  }, [pieCalculatorDS, maxCh]);

  return (
    <>
      <div className="margin-top-small">
        <div className="action-header">
          <h3 className="action-header__title">Pite Kalkulátor</h3>
          <Button onClick={addIngridient}>Új hozzávaló</Button>
        </div>

        <div>
          <p className="text-muted">Elfogyasztható szénhidrát</p>
          <Slider min={0} max={160} value={maxCh} onValueChange={(chValue) => onMaxChChange(chValue)} />
        </div>


        <div>
          {pieCalculatorDS && pieCalculatorDS.length > 0 &&
            <div className="action-header margin-top-small">
              <h3 className="action-header__title">Hozzávalók</h3>
            </div>
          }
          {pieCalculatorDS.map((ingridient, index) =>
            <div key={index}>
              <IngridientSelector showRemove={true}
                                  selectedIngridient={ingridient}
                                  onIngridientSelected={(value) => setIngridientForCalculator(ingridient, value)}
                                  onRemoveIngridient={() => removeIngridient(ingridient)}/>

              <p style={{margin: 0}} className="text-muted">Mennyiség</p>
              <Slider min={0} max={1000} value={1} onValueChange={(chValue) => onWeightUpdated(ingridient, chValue)} />
            </div>
          )}
        </div>

        {summary &&
        <div className="margin-top-small">
          <h4>Összefoglaló:</h4>
          <p dangerouslySetInnerHTML={{__html: summary}}></p>
        </div>
        }
      </div>
    </>
  );
}
