import React, {useEffect, useState} from 'react';
import { Slider } from './Slider';
import { IngridientSelector } from './IngridientSelector';
import { ingridientList } from './ingridientList';
import {getRoutine} from "./dailyRoutine";

export function IngridientCalculator() {
  const [ingridientForCalculator, setIngridientForCalculator] = useState(ingridientList[0]);
  const [ingridientResult, setIngridientResult] = useState('');
  const [chValue, setChValue] = useState(getRoutine().ch);

  const onChMaxChange = (chValue) => {
    setChValue(parseInt(chValue));
  };

  const onIngridientSelected = (value) => {
    setIngridientForCalculator(value);
  };

  useEffect(() => {
    if (ingridientForCalculator && ingridientForCalculator?.ch) {
      const ingridientChValue = Math.floor((ingridientForCalculator.ch * 100) / chValue);
      setIngridientResult(`<strong>${ingridientChValue}g</strong> ${ingridientForCalculator.label} tartalmaz ${chValue}g szénhidrátot`);
    } else {
      setIngridientResult('');
    }
  }, [chValue, ingridientForCalculator]);

  return (
    <>
      <div className="margin-top-small">
        <div className="action-header">
          <h3 className="action-header__title">Szénhidrát Kalkulátor</h3>
          <div className="text-muted">
            <span>{getRoutine().routine}</span>
          </div>
        </div>
        <div>
          <p className="text-muted">Elfogyasztható szénhidrát</p>
          <Slider min={0} max={160} value={chValue} onValueChange={(chValue) => onChMaxChange(chValue)} />
        </div>

        <div className="action-header margin-top-small">
          <h3 className="action-header__title">Hozzávaló</h3>
        </div>

        <IngridientSelector selectedIngridient={ingridientForCalculator}
                            onIngridientSelected={onIngridientSelected} />

        {ingridientResult &&
          <div className="margin-top-small">
            <h4>Összefoglaló:</h4>
            <p dangerouslySetInnerHTML={{__html: ingridientResult}}></p>
          </div>
        }
      </div>
    </>
  );
}
