import Form from "react-bootstrap/Form";
import {ingridientList} from "./ingridientList";
import React, {useState} from "react";
import Button from "react-bootstrap/Button";

export function IngridientSelector({ showRemove, selectedIngridient, onIngridientSelected, onRemoveIngridient }) {
  const [otherSelected, setOtherSelected] = useState(false);

  const removeIngridient = () => {
    onRemoveIngridient();
  };

  const ingridientSelected = (ingridient) => {
    if (ingridient === '-1') {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
      const selection = ingridientList.find(ing => {
        return (ing.id || '').toString() === ingridient;
      });
      onIngridientSelected(selection);
    }
  };

  const onCustomIngridientChange = (chValue) => {
    onIngridientSelected({
      id: Math.floor(Math.random() * 1000000),
      label: 'Egyéb',
      ch: parseInt(chValue),
    })
  };

  return (
    <>
      <div className="ingridient-selector">
        <Form.Group>
          <Form.Control as="select"
                        defaultValue={selectedIngridient.id}
                        onChange={(event) => ingridientSelected(event.target.value)}>
            {ingridientList.map(ingridientItem => <option key={ingridientItem.id} value={ingridientItem.id}>{ingridientItem.label}</option>)}
          </Form.Control>
          {showRemove &&
            <Button variant="danger" onClick={() => removeIngridient()}>
              <i className="fas fa-minus" />
            </Button>
          }
        </Form.Group>
        {otherSelected &&
          <div style={{margin: '0 0 16px 48px'}}>
            100g tartalmaz <input type="number" className="form-control" onChange={(event) => onCustomIngridientChange(event.target.value)}/>g szenhidratot
          </div>
        }
      </div>
    </>
  );
}
