import './App.css';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import {IngridientCalculator} from "./IngridientCalculator";
import {PieCalculator} from "./PieCalculator";
import {PizzaCalculator} from "./PizzaCalculator";

function App() {
  const [activeState, setActiveState] = useState(0);

  const selectIngridientCalculator = () => setActiveState(0);
  const selectPizza = () => setActiveState(1);
  const selectChart = () => setActiveState(2);

  return (
    <>
      <div className="app-container">
        <div className="mode-selector">
          <Button variant={activeState === 0 ? 'primary' : 'light'} onClick={() => selectIngridientCalculator()}>
            <span className="fas fa-border-all" />
          </Button>
          {/*<Button variant={activeState === 1 ? 'primary' : 'light'} onClick={() => selectPizza(true)}>*/}
          {/*  <span className="fas fa-pizza-slice" />*/}
          {/*</Button>*/}
          <Button variant={activeState === 2 ? 'primary' : 'light'} onClick={() => selectChart(true)}>
            <span className="fas fa-chart-pie" />
          </Button>
        </div>

        <div className="view">
          {activeState === 0 && <IngridientCalculator/>}
          {activeState === 1 && <PizzaCalculator/>}
          {activeState === 2 && <PieCalculator/>}
        </div>
      </div>
    </>
  );
}

export default App;
