export const getRoutine = () => {
  const hour = (new Date()).getHours();
  if (hour < 10) {
    return {
      ch: 30,
      routine: 'reggeli',
    };
  }
  if (hour < 13) {
    return {
      ch: 20,
      routine: 'tízórai',
    };
  }
  if (hour < 15) {
    return {
      ch: 50,
      routine: 'ebéd',
    };
  }
  if (hour < 17) {
    return {
      ch: 20,
      routine: 'uzsonna',
    };
  }
  if (hour < 23) {
    return {
      ch: 40,
      routine: 'vacsora',
    };
  }
};
