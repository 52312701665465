import React, { useState } from 'react';
import './Slider.css';

export function Slider({value, min, max, onValueChange}) {
  const [chValue, setChValue] = useState(value);

  const addCh = (value) => {
    updateChValue(parseInt(chValue) + value);
  };

  const updateChValue = (value) => {
    setChValue(value);
    onValueChange(value);
  };

  return (
    <>
      <div className="slider-container">
        <button className="slider__add" disabled={chValue === '0'} onClick={() => addCh(-1)}>
          <i className="fas fa-minus"></i>
        </button>
        <input type="range" id="vol" name="vol" min={min} max={max} value={chValue}
               className="slider"
               onChange={(event) => updateChValue(event.target.value)}/>
        <button className="slider__contract" disabled={chValue === '160'}  onClick={() => addCh(1)}>
          <i className="fas fa-plus"></i>
        </button>
        <div className="slider__value">{chValue}g</div>
      </div>
    </>
  );
}
